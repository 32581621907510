import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
const ResetPasswordPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors({});
    setMessage('');
    // Perform the API call using fetch or a library like axios
    fetch('https://api-by-ai.vercel.app/api/auth/reset-password/', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
        confirmPassword,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (!data.success) {
          if (data.errors.token) {
            setMessage('Link expired');
          } else {
            setErrors(data.errors);
            setMessage(data.message);
          }
          setTimeout(() => {
            setMessage('');
          }, 3000);
        } else {
          setMessage(data.message);
          setSuccess(true);
          setTimeout(() => {
            setMessage('');
          }, 3000);
          // Handle successful login or redirect to another page
        }
      })
      .catch((error) => {
        setLoading(false);
        // Handle error
      });
  };

  return (
    <div>
      <div className='frm_cnt_wrap'>
        <div className='frm_cnt_inner flex0'>
          <div className='form_header'>
            <h3>Reset Password</h3>
          </div>
          <div className='form_wrap'>
            <div className='form_inner'>
              <div className={errors.password ? 'frm_grp error' : 'frm_grp'}>
                <label className='blind'>Email</label>
                <div>
                  <div className='frm_control'>
                    <input
                      type='password'
                      value={password}
                      placeholder='Enter New Password'
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {errors.password && (
                    <div className='error_message'>
                      {errors.password.map((message, index) => (
                        <p key={index}>{message}</p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={errors.confirmPassword ? 'frm_grp error' : 'frm_grp'}
              >
                <label className='blind'>confirm Password</label>
                <div>
                  <div className='frm_control'>
                    <input
                      type='password'
                      value={confirmPassword}
                      placeholder='Enter Confirm Password'
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  {errors.confirmPassword && (
                    <div className='error_message'>
                      {errors.confirmPassword.map((message, index) => (
                        <p key={index}>{message}</p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='form_footer'>
            <div className='bt_wrap w_full'>
              <a
                href='#none'
                className='bt_b50'
                id='btnSubmit'
                onClick={handleSubmit}
              >
                <span>Reset Password</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {message && (
        <div className={success ? 'ly_pop_toast success' : 'ly_pop_toast'}>
          <p>{message}</p>
        </div>
      )}
      {loading ? (
        <div className='loading-screen'>
          <div className='spinner'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default ResetPasswordPage;
