import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ResetPasswordPage from './pages/ResetPasswordPage';
import NotFoundPage from './pages/NotFoundPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path='/security/reeset-password/:token'
          element={<ResetPasswordPage />}
        />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};
export default App;
